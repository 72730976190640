import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Wrapper from "~/components/reusable/Wrapper"
import { ButtonLink } from "~/components/reusable/Button"
import Spacer from "~/components/reusable/svgs/Spacer"
import Loader from "~/components/Loader"

const NotFoundPage = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return (
      <Layout title="404" path="/404/">
        <div className=" bg-theme-orange-lighter">
          <Wrapper className="grid min-h-screen !place-items-center">
            <Loader loading={true} />
          </Wrapper>
        </div>
      </Layout>
    )
  }

  return (
    <Layout title="404: Not Found" path="/404/">
      <div className={`relative mt-[-153px] bg-theme-yellow pt-32`}>
        <div
          className={`dot absolute right-[-160px] top-[-170px] z-[0] h-[550px] w-[550px] rounded-full bg-theme-green-lighter md:right-[-320px] md:top-[-340px] md:h-[1058px] md:w-[1058px]`}
        />
        <Wrapper className={`relative z-[2]`}>
          <div
            className={`flex-set-0-0 mx-1/12 my-20 basis-5/6 md:my-32 md:basis-5/12`}
          >
            <h2 className="mb-3 px-3 text-h2 font-bold tracking-tight md:text-h1">
              404: Not Found
            </h2>
            <p className={`mb-8 px-3 leading-8 [&_a]:font-bold`}>
              You just hit a route that doesn't exist.
            </p>
            <ButtonLink
              buttonStyle={`primary`}
              buttonColor={`orange`}
              link={`/`}
              text={`Back to Home Page`}
            />
          </div>
        </Wrapper>
        <div className={`relative z-[1]`}>
          <Spacer top={`yellow`} bottom={`black`} />
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
